import React from "react";
import "./Part1.css";
import { Box, Paper, Grid } from "@mui/material";
import count from "./count.png";
import mint from "./mint.png";
import figure from "./Main Fgure.png";
import Countdown from "../Countdown/Countdown";
import { NavLink } from "react-router-dom";

function Part1() {
  const d = new Date();
  d.setDate(4);
  const parsedUnixTime = parseInt((new Date('2021.04.11') / 1000).toFixed(0))

  const secondsSinceEpoch = Math.round(Date.now() / 1000)

    return (<>
        <div className="part1__bg"
        >
        <Box  sx={{ flexGrow: 1 }}>
      <Grid container  alignItems="center" spacing={1}>
        <Grid  item xs={12} md={6}>
  <img width="90%" height="90%" src={figure} alt=""/>
        </Grid>
        <Grid className="countdown__container" item xs={12} md={6}>
       
        <div  className="countdown__body">
            <div className="presale">
            <img  width="100%" height="100%" src={count} alt=""/>
            </div>


   
     <Countdown 		timeTillDate="11 12 2021, 23:59 pm" 
		timeFormat="MM DD YYYY, h:mm a"  />
  
        </div>  
        <div className="countdown__align" style={{textAlign:'center'}}>
        {/* <div className="mint__now glow">MINT NOW</div>
        <img width="30%" height="30%" src={mint} alt=""/>
        </div>    */}   
           <NavLink exact to="/minting">
                <button className="btn glow">
                  <div className="btn-text2">MINT NOW</div>
                </button>
              </NavLink>
              </div>
              <div
                className="countdown__align text"
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                  color: "white",
                  fontSize: "0.7rem",
                }}
              >
                AVAILABLE ON MAGICEDEN.IO
              <div>
            
                </div>

              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default Part1;
